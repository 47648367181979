import React, { useEffect, useRef, useState, useCallback } from "react";
import useVisibility from "../../util/use-visibility";
import { formatTimezone, formatWeekdayShort, formatDateShort, formatMonth, formatDayOfMonth } from "../../util/format-datetime";
import Session from "./session";
import Tabs, { defaultFormat } from "./agenda-tabs";
import { TimezoneDropdown, TracksDropdown } from "./dropdown";
import Button from "../button";

/**
 * Formats the dates for the current timezone,
 * in all the ways it needs to be formatted
 * Associates sessions with their respective dates.
 */
const useDates = (sessions, displayedTimezone) => {
  const [datesArr, setDatesArr] = useState(calculateDays());
  const [currentDateIndex, setCurrentDateIndex] = useState(0);

  function calculateDays() {
    // create array for each date
    let datesArr = [];
    sessions.forEach((session) => {
      const dateStr = formatDateShort(session.start_datetime, displayedTimezone);
      // find the startdate
      let dateObj = datesArr.find((obj) => obj.startdate === dateStr);
      // if startdate doesn't exist add new date
      if (!dateObj) {
        dateObj = {
          weekday: formatWeekdayShort(session.start_datetime, displayedTimezone),
          startdate: dateStr,
          sessionlist: [],
          month: formatMonth(session.start_datetime, displayedTimezone),
          dayOfMonth: formatDayOfMonth(session.start_datetime, displayedTimezone),
        };
        datesArr.push(dateObj);
      }
      // then add the session data for that date
      dateObj.sessionlist.push(session);
    });
    return datesArr;
  }

  useEffect(() => {
    const oldNumDays = datesArr.length;
    const newDays = calculateDays();
    setDatesArr(newDays);
    if (oldNumDays !== newDays.length) {
      setCurrentDateIndex(datesArr[0]);
    }
  }, [displayedTimezone]);

  return {
    datesArr,
    currentDateIndex,
    setCurrentDateIndex,
  };
};

const Agenda = ({
  sessions = [],
  speakers = [],
  underwriters = [],
  tracks = [],
  is_virtual,
  is_hybrid_event,
  timezone,
  has_underwritten_session,
  agenda_description,
  agenda_tab_labels = [],
  status,
}) => {
  const ref = useRef(null);
  useVisibility(ref, "agenda");
  const [displayedTimezone, setDisplayedTimezone] = useState(timezone);
  const [showTimezonePicker, setShowTimezonePicker] = useState(false);
  const { datesArr, currentDateIndex, setCurrentDateIndex } = useDates(sessions, displayedTimezone);
  const [track, setSelectedTrack] = useState("");
  const [format, setFormat] = useState(defaultFormat);

  useEffect(() => {
    // only show timezone picker if it is a hybrid event and the local time doesn't match the
    // event timezone
    setShowTimezonePicker(
      is_hybrid_event && formatTimezone(timezone) !== formatTimezone(undefined)
    );

    if (is_virtual && !is_hybrid_event) {
      setDisplayedTimezone(undefined);
    }
  }, []);

  const onChangeTimezone = (val) => {
    setDisplayedTimezone(val);
  };

  if (!sessions.length) {
    return null;
  }

  const onReset = () => {
    setSelectedTrack("");
    setFormat(defaultFormat);
  };

  return (
    <section ref={ref} id="agenda" className="c-agenda alt-bg-color">
      <AgendaHeader
        showTimezonePicker={showTimezonePicker}
        displayedTimezone={displayedTimezone}
        onChangeTimezone={onChangeTimezone}
        timezone={timezone}
        has_underwritten_session={has_underwritten_session}
        agenda_description={agenda_description}
        tracks={tracks}
        track={track}
        setSelectedTrack={setSelectedTrack}
        is_hybrid_event={is_hybrid_event}
        format={format}
        setFormat={setFormat}
        datesArr={datesArr}
      />

      <Tabs
        datesArr={datesArr}
        currentDateIndex={currentDateIndex}
        setCurrentDateIndex={setCurrentDateIndex}
        customTabNames={agenda_tab_labels}
      />

      <Sessions
        key={currentDateIndex}
        sessionlist={datesArr[currentDateIndex].sessionlist}
        speakers={speakers}
        underwriters={underwriters}
        displayedTimezone={displayedTimezone}
        track={track}
        onReset={onReset}
        format={format}
        status={status}
      />
    </section>
  );
};

export const AgendaHeader = ({
  showTimezonePicker,
  displayedTimezone,
  onChangeTimezone,
  timezone,
  has_underwritten_session,
  agenda_description,
  tracks,
  track,
  setSelectedTrack,
  format = defaultFormat,
  setFormat,
  is_hybrid_event,
  datesArr,
}) => {
  // Format the header date
  const startMonth = datesArr[0].month;
  const endMonth = datesArr[datesArr.length - 1].month;
  const startDay = datesArr[0].dayOfMonth;
  let endDay = datesArr[datesArr.length - 1].dayOfMonth;

  // if the end date is a new month, include it in short format
  if (startMonth !== endMonth) {
    endDay = endMonth + ". " + endDay;
  }

  const formattedDateRange = `${startMonth}. ${startDay}-${endDay}`;

  return (
    <header className="c-agenda-header">
      <h2 className={`events c-agenda-header__title`}>
        Agenda
        {datesArr && (
          <span className="c-agenda-header__date">
            {datesArr.length - 1 > 0 ? formattedDateRange : `${startMonth} ${startDay}`}
          </span>
        )}
      </h2>
      <div className="c-agenda-header__description">
        {agenda_description && <div dangerouslySetInnerHTML={{ __html: agenda_description }} />}
        {has_underwritten_session && (
          <p>
            *Underwriter sessions not produced by <i>The Atlantic</i>’s Editorial Team.
          </p>
        )}
      </div>

      <div className="c-agenda-header-filters">
        <TimezoneDropdown
          showTimezonePicker={showTimezonePicker}
          displayedTimezone={displayedTimezone}
          onChangeTimezone={onChangeTimezone}
          timezone={timezone}
        />
        <TracksDropdown tracks={tracks} selected={track} setSelected={setSelectedTrack} />
        {is_hybrid_event && <Format format={format} setFormat={setFormat} />}
      </div>
    </header>
  );
};

const Sessions = ({
  sessionlist,
  speakers,
  underwriters,
  displayedTimezone,
  track,
  onReset,
  format,
  status,
}) => {
  const [filteredList, setFilteredList] = useState(sessionlist);

  useEffect(() => {
    const newFilteredList = sessionlist.filter((session) => {
      if (track) {
        const foundTrack = session.tracks.find((t) => t.slug === track);
        if (!foundTrack) return false;
      }
      if (!format.virtual && session.format === "VIRTUAL") {
        return false;
      }
      if (!format.inPerson && session.format === "IN_PERSON") {
        return false;
      }
      if (!format.inPerson && !format.virtual && session.format === "BOTH") {
        return false;
      }
      return true;
    });
    setFilteredList(newFilteredList);
  }, [track, format, sessionlist]);

  return (
    <div className={`c-agenda-sessions`}>
      {!filteredList.length ? (
        <ResetButton onReset={onReset} />
      ) : (
        filteredList.map((session, index) => (
          <Session
            key={index}
            session={session}
            speakersAll={speakers}
            underwritersAll={underwriters}
            displayedTimezone={displayedTimezone}
            status={status}
          />
        ))
      )}
    </div>
  );
};

const ResetButton = ({ onReset }) => {
  return (
    <div className="c-agenda-reset">
      <p className="c-agenda-reset__text">
        There are no events with your selected criteria on this day. Please try another day or reset
        your criteria.
      </p>
      <Button className="c-agenda-reset__button" label="Reset" onClick={onReset} role="button" />
    </div>
  );
};

const Format = ({ format, setFormat }) => {
  const handleChange = useCallback(
    (e) => {
      setFormat((oldFormat) => {
        if (e.target.name === "virtual") {
          return { ...oldFormat, virtual: e.target.checked };
        } else {
          return { ...oldFormat, inPerson: e.target.checked };
        }
      });
    },
    [setFormat]
  );

  return (
    <div className="c-format-checkboxes">
      <span className="c-format-title">Format:</span>
      <div className="c-format-wrapper">
        <input
          className="c-format-checkbox"
          id="virtual"
          type="checkbox"
          checked={format.virtual}
          onChange={handleChange}
          name="virtual"
        />
        <label className="c-format-checkbox-label" htmlFor="virtual">
          Virtual
        </label>
      </div>
      <div className="c-format-wrapper">
        <input
          className="c-format-checkbox"
          id="inPerson"
          type="checkbox"
          checked={format.inPerson}
          name="inperson"
          onChange={handleChange}
        />
        <label className="c-format-checkbox-label" htmlFor="inPerson">
          In-Person
        </label>
      </div>
    </div>
  );
};

export default Agenda;
